// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_tQ";
export var lbContainerInner = "y_tR";
export var movingForwards = "y_tS";
export var movingForwardsOther = "y_tT";
export var movingBackwards = "y_tV";
export var movingBackwardsOther = "y_tW";
export var lbImage = "y_tX";
export var lbOtherImage = "y_tY";
export var prevButton = "y_tZ";
export var nextButton = "y_t0";
export var closing = "y_t1";
export var appear = "y_t2";