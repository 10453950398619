// extracted by mini-css-extract-plugin
export var dark = "v_sR";
export var darkcookie = "v_sS";
export var tintedcookie = "v_sT";
export var regularcookie = "v_sV";
export var darkbase = "v_sW";
export var tintedbase = "v_sX";
export var regularbase = "v_sY";
export var darkraw = "v_sZ";
export var tintedraw = "v_s0";
export var regularraw = "v_s1";
export var darkchick = "v_s2";
export var tintedchick = "v_s3";
export var regularchick = "v_s4";
export var darkherbarium = "v_s5";
export var tintedherbarium = "v_s6";
export var regularherbarium = "v_s7";
export var darkholiday = "v_s8";
export var tintedholiday = "v_s9";
export var regularholiday = "v_tb";
export var darkoffline = "v_tc";
export var tintedoffline = "v_td";
export var regularoffline = "v_tf";
export var darkorchid = "v_tg";
export var tintedorchid = "v_th";
export var regularorchid = "v_tj";
export var darkpro = "v_tk";
export var tintedpro = "v_tl";
export var regularpro = "v_tm";
export var darkrose = "v_tn";
export var tintedrose = "v_tp";
export var regularrose = "v_tq";
export var darktimes = "v_tr";
export var tintedtimes = "v_ts";
export var regulartimes = "v_tt";
export var darkwagon = "v_tv";
export var tintedwagon = "v_tw";
export var regularwagon = "v_tx";